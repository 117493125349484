import React, { useEffect, useState, useCallback } from "react";

import { Button, CircularProgress, TextField, MenuItem } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { hyphenatedDate } from "helperFuncs/dateFormat";
import { SpeechServices } from "helperFuncs/enums";
import { downloadFile, emptyCsv, generateCsv } from "helperFuncs/dataHelp";
import PageHeader from "components/Common/PageHeader";
import useAdminTools from "adminapi";
import "./MonthlyUsage.css";
import "components/Common/CsvTable.css";

const parseMonthlyReport = (report, selectedService) => {
	const csvHeaders = [
		"License No.",
		"Customer",
		"Contact Name",
		"Minutes Used"
	];
	const csvData = [];

	for(let licenseId in report) {
		const lic = report[licenseId];
		let used = lic.usage;
		if(used) {
			if(selectedService !== SpeechServices.GLOBAL) {
				used = used.filter(u=>u.service===selectedService);
			}
			used = used.reduce((sum, u)=>sum+u.minutes_used,0);
			if(used > 0){
				csvData.push([
					lic.license_no ?? "!id:"+licenseId,
					lic.customer_number,
					lic.customer_name,
					used,
				]);
			}
		}
	}

	return {
		csvHeaders,
		csvData,
	};
};

const MonthlyUsage = (/*{props}*/) => {
	const api = useAdminTools();
	const [report,setReport] = useState(null);
	const [selectedService,setSelectedService] = useState(SpeechServices.GOOGLE_SPEECH);
	const [startDate,setStartDate] = useState(()=>{
		let date = new Date();
		date.setHours(0,0,0,0);
		date.setDate(1);
		return date;
	});
	const [endDate,setEndDate] = useState(()=>{
		let date = new Date(startDate);
		
		date.setMonth(date.getMonth()+1);
		return date;
	});
	const [customRangeMode,setCustomRangeMode] = useState(false);

	useEffect(()=>{
		if(startDate > endDate) {
			setReport(emptyCsv());
		} else {
			const runReportUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/reports/usage?start_date=${
				hyphenatedDate(startDate)}&end_date=${hyphenatedDate(endDate)}`;
			setReport({loading:true});
			api.get(runReportUrl,{
				headers: api.noCache
			}).then(data=>{
				setReport(parseMonthlyReport(data.data.report,selectedService));
			}).catch(error=>{
				setReport({error:error});
				console.log(error);
			});
		}
	},[startDate,endDate,selectedService]);

	const handleSelectedService = (event) => {
		setSelectedService(event.target.value);
	};

	const saveResults = useCallback(() => {
		const blob = new Blob([generateCsv(report)], {type: "text/csv"});
		let filename = selectedService;
		if(customRangeMode) {
			filename += "-custom";
		} else {
			const inMonth = new Date(startDate);
			inMonth.setDate(inMonth.getDate()+15); //set date in middle of month
			const datePart = startDate.toLocaleString("default",{month:"short", year: "numeric"});
			filename += `-${datePart}`;
		}
		downloadFile(blob, filename+".csv");
	},[report,startDate,endDate,selectedService]);

	let content;

	if(report === null || report.loading) {
		content = <div className="centeredLoading">
			<CircularProgress />Generating Report...
		</div>;
	} else if(report.error) {
		content = <div className="centeredError">
			Usage Report error:
			<br />
			{report.error?.toString()}
			<br />
			<Button variant="outlined"
				className="retryButton"
				onClick={()=>setReport(null)}
			>
				Retry
			</Button>
		</div>;
	} else {
		let reportContent;
		if(startDate > endDate) {
			reportContent = "Start Date must be before End Date.";
		} else {
			reportContent = <table className="csvTable">
				<thead>
					<tr>
						{report.csvHeaders.map((str)=><th key={str}>{str}</th>)}
					</tr>
				</thead>
				<tbody>
					{report.csvData.map((row,i)=><tr key={i}>
						{row.map((str)=><td key={str}>{str}</td>)}
					</tr>)}
				</tbody>
			</table>;
		}
		content = <div className="monthlyUsageReport">
			<br />
			<LocalizationProvider dateAdapter={AdapterDateFns}>	
				<CustomRangePicker
					startDateState={[startDate,setStartDate]}
					endDateState={[endDate,setEndDate]}
					customRangeModeState={[customRangeMode,setCustomRangeMode]}
				/>
			</LocalizationProvider>
			<br />
			<br />
			<TextField select
				value={selectedService}
				label="Speech Service"
				size='small'
				className="reportParam"
				onChange={handleSelectedService}
			>
				<MenuItem value={SpeechServices.GLOBAL}>All Services</MenuItem>
				<MenuItem value={SpeechServices.GOOGLE_SPEECH}>Google Speech</MenuItem>
				<MenuItem value={SpeechServices.SPEECHMATICS}>Speechmatics</MenuItem>
				<MenuItem value={SpeechServices.WATSON}>Watson</MenuItem>
				<MenuItem value={SpeechServices.AZURE}>Azure</MenuItem>
				<MenuItem value={SpeechServices.KENSHO}>Kensho</MenuItem>
			</TextField>
			<br />
			<br />
			<Button className="downloadButton"
				onClick={saveResults}
			>
				Download Results
			</Button>
			<br />
			<br />
			{reportContent}
		</div>;
	}

	return <>
		<PageHeader title="Monthly Usage Report" />
		{content}
	</>;
};

function CustomRangePicker({startDateState,endDateState,customRangeModeState}) {
	const [startDate,setStartDate] = startDateState;
	const [endDate,setEndDate] = endDateState;
	const [customRangeMode,setCustomRangeMode] = customRangeModeState;
	if(customRangeMode) {
		return <div>
			<DatePicker
				label="Start Date"
				onChange={setStartDate}
				value={startDate}
				slotProps={{ 
					textField:{
						size:"small",
						className:"reportParam"
					}
				}}
			/>
			&nbsp;
			<DatePicker
				label="End Date"
				onChange={setEndDate}
				value={endDate}
				slotProps={{ 
					textField:{
						size:"small",
						className:"reportParam"
					}
				}}
			/>
		</div>;
	} else {
		const handleSelectMonth = start => {
			setStartDate(start);
			const end = new Date(start);
			end.setMonth(end.getMonth()+1);
			setEndDate(end);
		};
		return <div>
			<DatePicker
				views={["month", "year"]}
				label="Report Month"
				openTo="month"
				value={startDate}
				onChange={handleSelectMonth}
				slotProps={{ 
					textField:{
						size:"small",
						className:"reportParam"
					}
				}}
			/>
			&nbsp;
			<Button variant="outlined"
				onClick={()=>setCustomRangeMode(true)}
			>
				Custom Date Range
			</Button>
		</div>;
	}
}

export default MonthlyUsage;
